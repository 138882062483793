import React from "react";
import styled from "styled-components";
import { useMousePosition } from "../hooks/useMousePosition";
import { motion } from "framer-motion";

const CursorStyles = styled(motion.div)`
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 0.36vw;
    height: 0.36vw;
    padding: 1.5vw;
    border-radius: 100%;
    background-color: 0.1vw solid var(--blue);
    border: 0.1vw solid var(--blue);
    mix-blend-mode: lighten;
    z-index: 20;
    @media (hover: none) and (pointer: coarse), (max-width: 500px){
        display: none;
    }
`

const PointerStyles = styled.div`
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 0.6vw;
    height: 0.6vw;
    border-radius: 100%;
    background-color: var(--blue);
    transform: translate(-50%, -50%);
    z-index: 20;
    &.on-focus {
        background-color: transparent;
    }
    @media (hover: none) and (pointer: coarse), (max-width: 500px){
        display: none;
    }
`


const Cursor = () => {
    const { x, y, hoverNav } = useMousePosition();

    return (
        <>
            <CursorStyles
                animate={{
                    top: y,
                    left: x,
                    x: "-50%",
                    y: "-50%",
                    scale: hoverNav ? 1.8 : 1,
                    opacity: hoverNav ? 1 : 0.15,
                    border: hoverNav ? "0.1vw solid #e88f54" : "0.1vw solid #051a26",
                    backgroundColor: hoverNav ? "#051a26" : "#e88f54",
                }}
                transition={{ ease: "linear", duration: 0.15 }}
            />
            <PointerStyles
                className={hoverNav ? "on-focus" : ""}
                style={{
                    top: y,
                    left: x
                }}
            />
        </>
    )
}

export { Cursor }