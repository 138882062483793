import Facemed from "../assets/images/projects/facemed-sm.webp";
import Crqlar from "../assets/images/projects/crqlar-sm.webp";
import Appartment from "../assets/images/projects/appartment-sm.webp";
import Gspur from "../assets/images/projects/gspur-sm.webp";
import Ceevie from "../assets/images/projects/ceevie-sm.webp";
import Liasto from "../assets/images/projects/liasto-sm.webp";

export const WorksList = [
    {
        id: "facemed",
        title: "FACEMED",
        src: Facemed,
        leftFlex: 0,
        rightFlex: 1,
        thumbnailOffset: "60%",
        offsetX: 30,
        offsetY: -10,
    },
    {
        id: "crqlar",
        title: "CRQLAR",
        src: Crqlar,
        leftFlex: 2,
        rightFlex: 1,
        thumbnailOffset: "15%",
        offsetX: -10,
        offsetY: -2,
    },
    {
        id: "appartment",
        title: "APPARTMENT",
        src: Appartment,
        leftFlex: 1,
        rightFlex: 3,
        thumbnailOffset: "70%",
        offsetX: -10,
        offsetY: 5,
    },
    {
        id: "gspur",
        title: "G'SPUR",
        src: Gspur,
        leftFlex: 3,
        rightFlex: 1,
        thumbnailOffset: "20%",
        offsetX: 30,
        offsetY: -10,
    },
    {
        id: "ceevie",
        title: "CEEVIE",
        src: Ceevie,
        leftFlex: 1,
        rightFlex: 3,
        thumbnailOffset: "70%",
        offsetX: 30,
        offsetY: -2,
    },
    {
        id: "liasto",
        title: "LIASTO",
        src: Liasto,
        leftFlex: 3,
        rightFlex: 1,
        thumbnailOffset: "15%",
        offsetX: 25,
        offsetY: 10,
    }
]