import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Nav } from "../components/Nav";
import { useScroll } from "../hooks/useScroll";
import { MainSection } from "../components/project/MainSection";
import { SecondarySection } from "../components/project/SecondarySection";
import { PhoneSection } from "../components/project/PhoneSection";
import { DesktopSection } from "../components/project/DesktopSection";
import { TabletSection } from "../components/project/TabletSection";
import { ProjectFooter } from "../components/project/ProjectFooter";
import { useData } from "../hooks/useData";
import { Panels } from "../components/Panels";
import { Animations } from "../Animations";


const ProjectStyles = styled(motion.div)`
padding: 6vw 5vw;
padding-right: 6vw;
.number{
    display: flex;
    justify-content: flex-start;
    position: relative;
    left: -26%;
    overflow: hidden;
    margin: 5vw 0;
    @media (hover: none) and (pointer: coarse), (max-width: 500px){
        left: -38%;
        width: 120%;
        margin: 15vw 0;
    }
    &-wrapper{
        overflow: hidden;
        h1{
            font-size: calc(var(--VW)*40);
            line-height: 33vw;
            height: 33vw;
            position: relative;
            bottom: -10%;
            @media (hover: none) and (pointer: coarse), (max-width: 500px){
                font-size:calc(var(--VW)*60);
                line-height: 50vw;
                height: 50vw;
            }
        }
    }
}
`

const Project = () => {
    const { scrollRef } = useScroll();
    const { projectID } = useParams();
    const { getData } = useData();
    const { transition, angleTextReveal } = Animations();

    const { title,
        sequence,
        text,
        secondary,
        phoneImgSrc,
        desktopImgSrc,
        tabletImgSrc,
        mobileFirst,
        githubLink,
        liveLink,
        next
    } = getData(projectID);

    return (
        <>
            <Nav />
            <Panels />
            <ProjectStyles
                initial={{ backgroundColor: "transparent", pointerEvents: "none" }}
                animate={{ backgroundColor: "unset", pointerEvents: "unset" }}
                exit={{ opacity: [1, 1, 0], transition: { duration: 2, times: [0, 0.99, 1] } }}
                ref={scrollRef} data-scroll-container>
                <div data-scroll-section className="number">
                    <div className="number-wrapper">
                        <motion.h1 variants={angleTextReveal} initial="initial" animate="animate" transition={{ ...transition, duration: 2, delay: 3 }}>0</motion.h1>
                    </div>
                    <div className="number-wrapper">
                        <motion.h1 variants={angleTextReveal} initial="initial" animate="animate" transition={{ ...transition, duration: 2, delay: 3.4 }}>{sequence}</motion.h1>
                    </div>
                </div>
                <MainSection title={title} texts={text} githubLink={githubLink} liveLink={liveLink} />
                <SecondarySection secondary={secondary} />
                {
                    mobileFirst && desktopImgSrc.length > 0 && phoneImgSrc.length > 0 &&
                    <>
                        <PhoneSection phoneImgSrc={phoneImgSrc} />
                        <DesktopSection desktopImgSrc={desktopImgSrc} />
                    </>
                }
                {
                    mobileFirst && desktopImgSrc.length === 0 && phoneImgSrc.length > 0 &&
                    <PhoneSection phoneImgSrc={phoneImgSrc} />
                }
                {
                    !mobileFirst && phoneImgSrc.length > 0 &&
                    <>
                        <DesktopSection desktopImgSrc={desktopImgSrc} />
                        <PhoneSection phoneImgSrc={phoneImgSrc} />
                    </>
                }
                {
                    tabletImgSrc.length > 0 &&
                    <TabletSection tabletImgSrc={tabletImgSrc} />
                }
                <ProjectFooter next={next} />
            </ProjectStyles>
        </>
    )
}

export { Project }