import FacemedLaptopMain from "../assets/images/projects/facemed/facemed-laptop-main.png";
import FacemedLaptopSide from "../assets/images/projects/facemed/facemed-laptop-side.png";
import FacemedLG1 from "../assets/images/projects/facemed/facemed-lg-1.webp";
import FacemedLG2 from "../assets/images/projects/facemed/facemed-lg-2.webp";
import FacemedLG3 from "../assets/images/projects/facemed/facemed-lg-3.webp";
import FacemedTabletMain from "../assets/images/projects/facemed/facemed-tablet-main.webp";
import FacemedTabletSide from "../assets/images/projects/facemed/facemed-tablet-side.webp";

import CrqlarLaptopMain from "../assets/images/projects/crqlar/crqlar-laptop-main.png";
import CrqlarLaptopSide from "../assets/images/projects/crqlar/crqlar-laptop-side.png";
import CrqlarLG1 from "../assets/images/projects/crqlar/crqlar-lg-1.png";
import CrqlarLG2 from "../assets/images/projects/crqlar/crqlar-lg-2.png";
import CrqlarLG3 from "../assets/images/projects/crqlar/crqlar-lg-3.png";
import CrqlarTabletMain from "../assets/images/projects/crqlar/crqlar-tablet-main.png";
import CrqlarTabletSide from "../assets/images/projects/crqlar/crqlar-tablet-side.png";

import AppartmentLaptopMain from "../assets/images/projects/appartment/appartment-laptop-main.png";
import AppartmentLaptopSide from "../assets/images/projects/appartment/appartment-laptop-side.png";
import AppartmentLG1 from "../assets/images/projects/appartment/appartment-lg-1.png";
import AppartmentLG2 from "../assets/images/projects/appartment/appartment-lg-2.png";
import AppartmentLG3 from "../assets/images/projects/appartment/appartment-lg-3.png";
import AppartmentTabletMain from "../assets/images/projects/appartment/appartment-tablet-main.png";
import AppartmentTabletSide from "../assets/images/projects/appartment/appartment-tablet-side.png";

import GspurLaptopMain from "../assets/images/projects/gspur/gspur-laptop-main.webp";
import GspurLaptopSide from "../assets/images/projects/gspur/gspur-laptop-side.png";
import GspurLG1 from "../assets/images/projects/gspur/gspur-lg-1.png";
import GspurLG2 from "../assets/images/projects/gspur/gspur-lg-2.png";
import GspurLG3 from "../assets/images/projects/gspur/gspur-lg-3.png";
import GspurTabletMain from "../assets/images/projects/gspur/gspur-tablet-main.webp";
import GspurTabletSide from "../assets/images/projects/gspur/gspur-tablet-side.webp";

import CeevieLaptopSide from "../assets/images/projects/ceevie/ceevie-laptop-side.png";
import CeevieLaptopMain from "../assets/images/projects/ceevie/ceevie-laptop-main.png";
import CeevieLG1 from "../assets/images/projects/ceevie/ceevie-lg-1.png";
import CeevieLG2 from "../assets/images/projects/ceevie/ceevie-lg-2.png";
import CeevieLG3 from "../assets/images/projects/ceevie/ceevie-lg-3.png";
import CeevieTabletMain from "../assets/images/projects/ceevie/ceevie-tablet-main.png";
import CeevieTabletSide from "../assets/images/projects/ceevie/ceevie-tablet-side.png";

import LiastoLaptopMain from "../assets/images/projects/liasto/liasto-laptop-main.png";
import LiastoLaptopSide from "../assets/images/projects/liasto/liasto-laptop-side.png";
import LiastoLG1 from "../assets/images/projects/liasto/liasto-lg-1.png";
import LiastoLG2 from "../assets/images/projects/liasto/liasto-lg-2.png";
import LiastoLG3 from "../assets/images/projects/liasto/liasto-lg-3.png";
import LiastoTabletMain from "../assets/images/projects/liasto/liasto-tablet-main.png";
import LiastoTabletSide from "../assets/images/projects/liasto/liasto-tablet-side.png";

export const ProjectData = [
    {
        id: "facemed",
        sequence: 1,
        title: "FACEMED",
        text: ["Clinic of dermatology, dedical cosmetology, plastic surgery and gynaecology."],
        secondary: ["Adobe XD", "UI/UX"],
        phoneImgSrc: [FacemedLG1, FacemedLG2, FacemedLG3],
        desktopImgSrc: [FacemedLaptopMain, FacemedLaptopSide],
        tabletImgSrc: [FacemedTabletMain, FacemedTabletSide],
        mobileFirst: false,
        liveLink: "https://www.facemed.gr",
        next: "crqlar"
    },
    {
        id: "crqlar",
        sequence: 2,
        title: "CRQLAR",
        text: ["CRQLAR unifies all guest information that was previously only found in the host's head and in different systems. This enables every employee to offer the guest a better and more efficient hotel experience at every touch point."],
        secondary: ["Adobe XD", "UI/UX"],
        phoneImgSrc: [CrqlarLG1, CrqlarLG2, CrqlarLG3],
        desktopImgSrc: [CrqlarLaptopMain, CrqlarLaptopSide],
        tabletImgSrc: [CrqlarTabletMain, CrqlarTabletSide],
        mobileFirst: false,
        liveLink: "https://www.crqlar.com/",
        next: "appartment"
    },
    {
        id: "appartment",
        sequence: 3,
        title: "APPARTMENT",
        text: ["A cloud-based apartment management system for apartments"],
        secondary: ["Figma", "UI/UX"],
        phoneImgSrc: [AppartmentLG1, AppartmentLG2, AppartmentLG3],
        desktopImgSrc: [AppartmentLaptopMain, AppartmentLaptopSide],
        tabletImgSrc: [AppartmentTabletMain, AppartmentTabletSide],
        mobileFirst: false,
        liveLink: "https://www.app-artment.gr",
        next: "gspur"
    },
    {
        id: "gspur",
        sequence: 4,
        title: "G'SPUR",
        text: ["Content & creative agency, full of curiosity, spirit and ideas."],
        secondary: ["Adobe XD", "UI/UX"],
        phoneImgSrc: [GspurLG1, GspurLG2, GspurLG3],
        desktopImgSrc: [GspurLaptopMain, GspurLaptopSide],
        tabletImgSrc: [GspurTabletMain, GspurTabletSide],
        mobileFirst: false,
        liveLink: "https://www.dasgspuer.com",
        next: "ceevie"
    },
    {
        id: "ceevie",
        sequence: 5,
        title: "CEEVIE",
        text: ["A video CV can help you stand out from the competition and showcase your personality, creativity, and communication skills. It also allows you to provide more information about yourself and your experience than a traditional paper CV. Ceevie provides employers a unique and engaging way to learn about the candidate."],
        secondary: ["Adobe XD", "UI/UX"],
        phoneImgSrc: [CeevieLG1, CeevieLG2, CeevieLG3],
        desktopImgSrc: [CeevieLaptopMain, CeevieLaptopSide],
        tabletImgSrc: [CeevieTabletMain, CeevieTabletSide],
        mobileFirst: false,
        liveLink: "https://ceevie.com",
        next: "liasto"
    },
    {
        id: "liasto",
        sequence: 6,
        title: "LIASTO",
        text: ["Liasto was founded in 2010 by the Nikolaou family in the mountain village of Kyrtoni in Fthiotida. The love and knowledge on the industrial tomato led to the creation of the sun-dried tomato production unit."],
        secondary: ["Webflow", "Adobe XD", "UI/UX"],
        phoneImgSrc: [LiastoLG1, LiastoLG2, LiastoLG3],
        desktopImgSrc: [LiastoLaptopMain, LiastoLaptopSide],
        tabletImgSrc: [LiastoTabletMain, LiastoTabletSide],
        mobileFirst: false,
        next: "facemed"
    },
]