import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { Animations } from "../../Animations";

//styles
const NamesStyles = styled(motion.div)`
    text-align: right;
    .name-wrapper{
        overflow: hidden;
        li{
            font-family: Monument;
            font-weight: 600;
            display: inline-block;
            font-size:calc(var(--VW)*6);
            line-height: calc(var(--VW)*6);
            height: calc(var(--VW)*6);
            text-transform: uppercase;
            -webkit-text-fill-color: var(--blue);
            -webkit-text-stroke: calc(var(--VW)*0.14) var(--pumpkin);
            @media (hover: none) and (pointer: coarse){
                font-size:calc(var(--VW)*11);
                line-height: calc(var(--VW)*11);
                height: calc(var(--VW)*16);
            }
        }
    }
`

//animations 

const titleParent = {
    hidden: {
        opacity: 1,
    },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.1,
            delayChildren: 3.5
        }
    }
}

const titleParent2 = {
    hidden: {
        opacity: 1,
    },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.1,
            delayChildren: 4
        }
    }
}

const titleTextReveal = {
    hidden: {
        y: "120%",
    },
    show: {
        y: "0%",
    }
}

//component
const Names = () => {
    const panagiotis = ["P", "A", "N", "A", "G", "I", "O", "T", "I", "S"]
    const kyriakos = ["K", "Y", "R", "I", "A", "K", "O", "S"];
    const { transition } = Animations();
    return (
        <NamesStyles>
            <motion.ol className="name-wrapper" variants={titleParent} initial="hidden" animate="show">
                {
                    panagiotis.map((letter, index) => {
                        return (
                            <motion.li key={index} variants={titleTextReveal} transition={{ ...transition, duration: 1 }}>{letter}</motion.li>
                        )
                    })
                }
            </motion.ol>
            <motion.ol className="name-wrapper" variants={titleParent2} initial="hidden" animate="show">
                {
                    kyriakos.map((letter, index) => {
                        return (
                            <motion.li key={index} variants={titleTextReveal} transition={{ ...transition, duration: 1 }}>{letter}</motion.li>
                        )
                    })
                }
            </motion.ol>
        </NamesStyles>
    )
}

export { Names }