import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import hoverEffect from "hover-effect";
import HeatMap from "../../assets/images/homepage/heightMap.webp";
import Pkyriakos from "../../assets/images/homepage/profile.jpeg";
import Pkyriakos2 from "../../assets/images/homepage/profile2.jpeg";

const MainSectionStyles = styled.div`
    margin: 20vh 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (hover: none) and (pointer: coarse), (max-width: 500px){
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-bottom: 10vh;
    }
    .main-pic{
        width: 25vw;
        height: 30vw;
        margin-right: 5vw;
        text-align: center;
        overflow: hidden;
        @media (hover: none) and (pointer: coarse), (max-width: 500px){
            width: 100%;
            width: 50vw;
            height: 55vw;
        }
    }
    .main-text{
        width: 45%;
        @media (hover: none) and (pointer: coarse), (max-width: 500px){
            width: 95%;
        }
        p{
            margin: 3vw 0;
            mix-blend-mode: normal;
            @media (hover: none) and (pointer: coarse), (max-width: 500px){
                margin: 5vw 0;
            }
            a{
                font-family:   Grotesk;
                text-transform: none;
                mix-blend-mode: normal;
            }
        }
    }
`

const MainSection = () => {
    const profileRef = useRef(null);
    useEffect(() => {
        new hoverEffect({
            parent: profileRef.current,
            intensity: 1.4,
            image1: Pkyriakos2,
            image2: Pkyriakos,
            displacementImage: HeatMap
        })
    });
    return (
        <MainSectionStyles data-scroll-section className="main">
            <div data-scroll data-scroll-speed="6" className="main-pic" ref={profileRef}></div>
            <div className="main-text">
                <p>For whatever reason that brought you to my portfolio, welcome. I hope you enjoyed browsing this site as much as I enjoyed building it. First off, please allow me to introduce myself.</p>
                <p>I'm currently a freelance Fullstack Designer (Front-End Developer & Web Designer) based in Livadeia, Greece. Prior to that, I completed my master's degree at University of the Aegean studying Computer Science.</p>
                <p>I strive to craft memorable experiences on the digital canvas. More than ever, companies and brands are using websites as a platform to build their online presence. I’m committed to creating refined digital solutions and interactive experiences that help businesses to succeed in the increasingly crowded digital landscape. </p>
            </div>
        </MainSectionStyles>
    )
}

export { MainSection }